.nav-link:hover {
        
}

.bg-navbar {
    background-color: #F5F5F5 !important;
    border-width: 0 !important;
}

.name {
    padding-left: 10px;
    font-size: 0.8em;
}
.dropdown-menu-wrapper {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 500px );
}
.caption {
    padding-left: 30px;
}
.notif-dropdown {
    border: 1px solid red;
}
.notif-title {
    font-size: 12px;
}
.notif-body {
    font-size: 10px;
}