.welcome {
    height: 50px;
    font-size: 1.75vw;
}

.time {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 700;
}

.body {
    min-height: 100vh; 
    margin:0; 
}