.deadline {
    background: #F6DB00 !important;
    cursor: context-menu !important;
}

.time-wrapper {
    background: #F6DB00 !important;
    width: 80px;
    height: 70px;
}

.time-deadline {
    background-color: #964B00;
    height: 36px;
    width: 50px;
}

.word {
    padding-bottom: 3px;
}