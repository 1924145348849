.wrapper {
    position: fixed;
    bottom: 20px;
    right: 5%;
    z-index: 1000
}

.wrapper a:nth-child(1) {
    background-color: green
}

.wrapper a:nth-child(2) {
    background-color: blue
}

.wrapper a:nth-child(3) {
    background-color: purple
}

.wrapper a:nth-child(4) {
    animation: 0.7s ease-out 0s 1 FadeIn;
    transition: all 0.3s
}

.wrapper a:hover:nth-child(4) {
    background-color: red
}

@keyframes FadeIn {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.hide {
    position: absolute;
    bottom: 0px !important;
    z-index: -1;
    transition: all 2s
}

.close-button-rotate {
    transform:rotate(45deg);
    
}

.wrapper a {
    height: 30px;
    width: 30px;
    background: #000;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    margin: 2px
}