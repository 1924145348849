.view-all {
    text-align: right !important;
    cursor: pointer;
}

.bg-default {
    background: #F0EDED;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: auto;
}