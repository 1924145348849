:root {
  --primary-color: #0108A0;
  --secondary-color: #ffffff;
  --bluesky: #5BC0DE;
  --green: #1FB5AD;
  --warning: #ffc107;
  --purple: #9466B5;
  font-family: 'Montserrat' !important;
  font-style: normal;
}

.bg-bluesky {
  background-color: var(--bluesky) !important;
  color: white !important;
  font-weight: bold !important;
}

.bg-red {
  background: #FF0000;
  color: white;
}
.bg-purple {
  background: var(--purple) !important;
  color: white !important;
}

.bg-green {
  background-color: var(--green) !important;
  color: white !important;
}

.bg-blue,
.btn-primary {
  background-color: var(--primary-color) !important;
  font-weight: bold !important;
}

.text-blue {
  color: var(--primary-color) !important;
  font-weight: bold !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.dropdown-item:hover {
  background-color: var(--warning) !important;
  cursor: pointer !important;
}
.pe-auto {
  cursor: pointer !important;
}