.header {
    height: 100px;
}

.text-header{
    width: 500px;
    align-self: center;
}

/* .label {
    padding-left: 50px;
} */
